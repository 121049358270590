import React from 'react'

import { Schema } from 'utils/Schema'

export interface MetaData {
  title: string
  description?: string
  author?: string

  publishedAt?: Date

  ogType?: string
  ogImageURL?: string
  ogImageWidth?: string
  ogImageHeight?: string

  twitterCard?: 'summary' | 'summary_large_image' | 'app' | 'player'
  twitterCreator?: string

  articleSection?: string
  articleTag?: string

  rssFeed?: {
    title: string
    url: string
  }

  themeColor?: string

  noindex?: boolean
}

export interface Props {
  url: string
  metaData: MetaData
  schema?: Schema
}

const PageMetaData: React.FC<Props> = (props) => {
  const { url, metaData, schema } = props

  const { themeColor = '#1b2433' } = metaData

  return (
    <>
      <link rel="shortcut icon" href="/favicon.ico" />

      <link rel="canonical" href={url} />
      <link rel="alternate" hrefLang="en" href={url} />
      <meta property="og:url" content={url} />

      {/* Title */}
      <title>{metaData.title}</title>
      <meta property="og:title" content={metaData.title} />

      {/* Description */}
      <meta name="description" content={metaData.description} />
      <meta property="og:description" content={metaData.description} />

      {metaData.publishedAt && (
        <meta
          property="article:published_time"
          content={metaData.publishedAt.toISOString().substring(0, 10)}
        />
      )}

      {/* Twitter */}
      <meta
        name="twitter:card"
        content={metaData.twitterCard || 'summary_large_image'}
      />
      <meta name="twitter:site" content="@luckypapermtg" />
      {metaData.twitterCreator && (
        <meta name="twitter:creator" content={metaData.twitterCreator} />
      )}

      {metaData.ogType && <meta property="og:type" content={metaData.ogType} />}

      <meta
        property="og:image"
        // TODO: interpolate correct host
        content={metaData.ogImageURL || 'https://luckypaper.co/lucky-paper.jpg'}
      />
      <meta
        property="og:image-width"
        content={metaData.ogImageWidth || '1200'}
      />
      <meta
        property="og:image-height"
        content={metaData.ogImageHeight || '630'}
      />

      <meta property="og:site_name" content="Lucky Paper" />

      {metaData.author && <meta name="author" content={metaData.author} />}
      {metaData.author && (
        <meta property="article:author" content={metaData.author} />
      )}

      {metaData.articleSection && (
        <meta property="article:section" content={metaData.articleSection} />
      )}
      {metaData.articleTag && (
        <meta property="article:tag" content={metaData.articleTag} />
      )}

      {metaData.rssFeed && (
        <link
          type="application/rss+xml"
          rel="alternate"
          title={metaData.rssFeed.title}
          href={metaData.rssFeed.url}
        />
      )}

      {schema && (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}

      <meta name="theme-color" content={themeColor} />
      {metaData.noindex && <meta name="robots" content="noindex, nofollow" />}
    </>
  )
}

export default PageMetaData
