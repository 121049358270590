import * as styles from './NewsletterSignUpForm.module.scss'

import React, { useLayoutEffect } from 'react'
import classNames from 'classnames'

interface Props {
  invertedColors?: boolean
}

const NewsletterSignUpForm: React.FC<Props> = (props) => {
  const { invertedColors } = props

  useLayoutEffect(() => {
    const script = document.createElement('script')

    script.src =
      'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js'
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <form
      className={classNames('js-cm-form', styles.container, {
        [styles.invertedColors]: invertedColors,
      })}
      id="subForm"
      action="https://www.createsend.com/t/subscribeerror?description="
      method="post"
      data-id="A61C50BEC994754B1D79C5819EC1255CE6BBC04B0DB9359254B5C040393890FB6BC0E9F0B8A0C909B5A5521D7965DD8D4B19DF24F5BD2A3165F8DA9C02E53093"
    >
      <label className={styles.label}>Email Address</label>
      <input
        aria-label="Email"
        className={classNames(styles.input, 'js-cm-email-input')}
        id="fieldEmail"
        maxLength={200}
        name="cm-wjuttd-wjuttd"
        required
        type="email"
      />

      <button className={styles.button} type="submit">
        Subscribe
      </button>
    </form>
  )
}

export default NewsletterSignUpForm
