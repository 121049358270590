import * as styles from './ArtCreditTag.module.scss'

import React from 'react'

import { useCardHover } from 'components/cards/CardHover'

interface Props {
  card: {
    id: string
    name: string
    set: string
    artist: string
  }
}

const ArtCreditTag: React.FC<Props> = (props) => {
  const { name, artist, set, id } = props.card

  const { hoverProps, portal } = useCardHover(name, set, id)

  return (
    <div className={styles.container} {...hoverProps}>
      <>
        {portal}
        <span className={styles.title}>{name}</span> — {artist}
      </>
    </div>
  )
}

export default ArtCreditTag
