import * as styles from './Footer.module.scss'

import React from 'react'
import { Link } from 'gatsby'

const Footer: React.FC = () => {
  return (
    <footer className={styles.container}>
      &copy; 2023{' '}
      <Link to="/sitemap/" className={styles.sitemapLink}>
        Lucky Paper
      </Link>
      . Card images courtesy of <a href="https://scryfall.com/">Scryfall</a>.
    </footer>
  )
}

export default Footer
