import * as styles from './Logotype.module.scss'

import React from 'react'
import { Link } from 'gatsby'

interface Props {
  subtitle?: string
}

const Logotype: React.FC<Props> = (props) => {
  const { subtitle } = props

  return (
    <div className={styles.container}>
      <Link to="/" className={styles.luckyPaper}>
        Lucky Paper
      </Link>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  )
}

export default Logotype
