import * as styles from './NewsletterSignUp.module.scss'

import React from 'react'

import * as Typography from 'components/typography'

import NewsletterSignUpForm from 'components/newsletter/NewsletterSignUpForm'

const NewsletterSignUp: React.FC = () => {
  return (
    <div className={styles.container}>
      <h3>
        <Typography.SecondaryHeading>
          Lucky Paper Newsletter
        </Typography.SecondaryHeading>
      </h3>
      <Typography.Paragraph>
        Our infrequent, text-only newsletter is a friendly way to stay
        up-to-date with what we&rsquo;re doing at Lucky Paper.{' '}
        <a href="/newsletter/">See past newsletters</a>
      </Typography.Paragraph>

      <NewsletterSignUpForm invertedColors={true} />
    </div>
  )
}

export default NewsletterSignUp
