import * as styles from './Layout.module.scss'

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { BaseLayout } from './Base'

import { AccentColor } from 'utils/accent-color/AccentColorContext'

import Header from './Header'
import Footer from './Footer'

import NewsletterSignUp from 'components/newsletter/NewsletterSignUp'
import OpenSurveyBanner from 'components/OpenSurveyBanner'

import ArtCreditTag from './ArtCreditTag'

export interface FrameCard {
  id: string
  name: string
  set: string
  artist: string
  imageURL: string
}

interface Props {
  children: React.ReactNode
  frameCard?: FrameCard | null
  accentColor?: string | null
  hideSurveyBanners?: boolean
  includeNewsletterSignup?: boolean
}

const Layout: React.FC<Props> = (props) => {
  const {
    children,
    frameCard,
    accentColor,
    hideSurveyBanners,
    includeNewsletterSignup,
  } = props

  const { surveys } = useStaticQuery<Queries.LayoutSetSurveysQuery>(
    graphql`
      query LayoutSetSurveys {
        surveys: allSetSurveysYaml(filter: { status: { eq: "active" } }) {
          sets: nodes {
            name
            code
          }
        }
      }
    `,
  )

  return (
    <BaseLayout>
      <AccentColor color={accentColor ?? undefined}>
        {!hideSurveyBanners && surveys.sets.length > 0 && (
          <OpenSurveyBanner sets={surveys.sets} />
        )}

        <div className={styles.container}>
          <div className={styles.background}>
            {frameCard && (
              <img
                className={styles.backgroundImage}
                src={frameCard.imageURL}
                alt=""
                role="presentation"
              />
            )}
          </div>
          <div className={styles.content}>
            <Header />
            {children}

            {includeNewsletterSignup && <NewsletterSignUp />}
          </div>
          <div className={styles.imageCreditWrapper}>
            {frameCard && <ArtCreditTag card={frameCard} />}
          </div>
        </div>

        <Footer />
      </AccentColor>
    </BaseLayout>
  )
}

export default Layout
