import * as styles from './Header.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import Logotype from './Logotype'

const Header: React.FC = () => {
  return (
    <header className={styles.container}>
      <nav role="navigation" className={styles.navigation}>
        <div className={styles.logotypeWrapper}>
          <h2>
            <Logotype />
          </h2>
        </div>
        <Link
          className={styles.navigationLink}
          activeClassName={styles.active}
          partiallyActive
          to="/resources/"
        >
          resources
        </Link>
        <Link
          className={styles.navigationLink}
          activeClassName={styles.active}
          partiallyActive
          to="/articles/"
        >
          Articles
        </Link>
        <Link
          className={styles.navigationLink}
          activeClassName={styles.active}
          partiallyActive
          to="/podcast/"
        >
          Podcast
        </Link>
        <Link
          className={styles.navigationLink}
          activeClassName={styles.active}
          partiallyActive
          to="/shop/"
        >
          Shop
        </Link>
        <Link
          className={styles.navigationLink}
          activeClassName={styles.active}
          partiallyActive
          to="/about/"
        >
          About
        </Link>
      </nav>
    </header>
  )
}

export default Header
