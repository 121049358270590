// title: Lucky Paper Shop
// frameCardName: Rishadan Pawnshop
// frameCardSet: MMQ
// lastUpdatedDate: 2024-03-15

import React from 'react'

import Layout from 'components/layout/Layout'
import PageMetaData from 'src/components/layout/PageMetaData'
import CubeConPrintPage from 'src/components/shop/CubeConPrintPage'

interface Props {
  pageContext: any
}

const ShopPage: React.FC<Props> = (props) => {
  return (
    <Layout
      {...props.pageContext}
      accentColor="#0562F3"
      includeNewsletterSignup
    >
      <CubeConPrintPage />
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Cube Con 2023 Print — Lucky Paper Shop',
        description:
          'Prints, products, and merch for Cube and other casual Magic: the Gathering Formats.',
        ogImageURL: 'https://luckypaper.co/og-images/cube-con-2023-print.jpg',
      }}
    />
  )
}

export default ShopPage
