import * as styles from './CubeConPrintPage.module.css'

import React from 'react'
import { Link } from 'gatsby'

import { SecondaryDivider } from '../typography'

import ProductLayout from './ProductLayout'

import CubeConPrintImage from './images/cube-con-2023-print-photo-1.jpg'
import CubeConPrintDetail1Image from './images/cube-con-2023-print-photo-2.jpg'
import CubeConPrintDetail2Image from './images/cube-con-2023-print-photo-3.jpg'
import CubeConPrintDetail3Image from './images/cube-con-2023-print-photo-detail-1.jpg'

const CubeConPrintPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <ProductLayout
        imageColumn={
          <>
            <img
              className={styles.image}
              src={CubeConPrintImage}
              alt="CubeCon 2023 Cube Map Print — Full Size"
            />
            <img
              className={styles.image}
              src={CubeConPrintDetail2Image}
              alt="CubeCon 2023 Cube Map Print — Detail 1"
            />
            <img
              className={styles.image}
              src={CubeConPrintDetail1Image}
              alt="CubeCon 2023 Cube Map Print — Zoomed In"
            />
            <img
              className={styles.image}
              src={CubeConPrintDetail3Image}
              alt="CubeCon 2023 Cube Map Print — Detail 2"
            />
          </>
        }
      >
        <div className={styles.info}>
          <h1 className={styles.title}>CubeCon 2023 Cube Map Print</h1>
          <div className={styles.description}>
            Celebrate the biggest Cube event of 2023 with a high quality,
            digital print of the Lucky Paper Cube Map, with all 48 featured
            cubes from the second CubeCon highlighted and labeled with their
            designer and geographic origin.
          </div>
          <ul className={styles.details}>
            <li>18&#x2033; x 24&#x2033; (~457mm x ~610mm)</li>
            <li>Archival Quality Giclée Print</li>
            <li>EPSON Enhanced Matte, Bright White 192 GSM Paper Stock</li>
            <li>
              Locally printed by a{' '}
              <a
                href="https://www.alpha-graphics.net/"
                className={styles.inlineLink}
              >
                certified minority and woman owned print shop
              </a>
            </li>
          </ul>
          <div className={styles.description}>
            This is a pre-order. Posters will be delivered in person at CubeCon,
            each carefully rolled up in a cardboard shipping tube.{' '}
            <strong>
              This preorder is for attendees of CubeCon only. If you are not at
              CubeCon, you will not get your print. They will not be shipped.
            </strong>
          </div>
          <div className={styles.soldOut}>Sold Out</div>
          <SecondaryDivider />
          <div className={styles.faq}>
            <div className={styles.question}>What is this?</div>
            <div className={styles.answer}>
              This is a print of the{' '}
              <Link to="/resources/cube-map" className={styles.inlineLink}>
                Cube Map
              </Link>{' '}
              we developed, customized with labels for all of the Cubes featured
              at{' '}
              <a href="https://mtgcubecon.com/" className={styles.inlineLink}>
                CubeCon 2023
              </a>
              . It depicts all of the cubes on Cube Cobra, positioned based on
              the cards they contain.{' '}
              <Link
                to="/articles/mapping-the-magic-landscape/"
                className={styles.inlineLink}
              >
                Read more about how the map was made.
              </Link>
            </div>
          </div>
          <div className={styles.faq}>
            <div className={styles.question}>
              How long will these be on sale for?
            </div>
            <div className={styles.answer}>
              The preorder will close on October 6th at 5pm eastern. This is to
              allow for enough time for production and packing.
            </div>
          </div>
          <div className={styles.faq}>
            <div className={styles.question}>
              I&rsquo;m not attending CubeCon — can I still purchase a print?
            </div>
            <div className={styles.answer}>
              Unfortunately, no. We will not be shipping these prints, they will
              only be delivered in person at CubeCon.
            </div>
          </div>
          <div className={styles.faq}>
            <div className={styles.question}>
              But what if I really, really want one but I am not going to Cube
              Con?
            </div>
            <div className={styles.answer}>
              If you can coordinate with someone who is attending the event we
              can deliver it to them and you can get it from them later. Just
              keep in mind that we can&rsquo;t be held responsible if something
              happens in transit.
            </div>
          </div>
          <div className={styles.faq}>
            <div className={styles.question}>
              What if I was going to attend CubeCon but I get sick or have a
              last-minute emergency and cannot attend?
            </div>
            <div className={styles.answer}>
              If you purchased a CubeCon pass but are unable to attend for some
              reason we will do our best to work with you and get your print
              into your hands. Please email us at{' '}
              <a className={styles.inlineLink} href="mailto:mail@luckypaper.co">
                mail@luckypaper.co
              </a>
              .
            </div>
          </div>
          <div className={styles.faq}>
            <div className={styles.question}>Can I purchase more than one?</div>
            <div className={styles.answer}>
              Yep. You can purchase as many as you&rsquo;d like as long as you
              can pick them all up at CubeCon.
            </div>
          </div>
          <div className={styles.faq}>
            <div className={styles.question}>
              Will these ever be reprinted in the future? Do you plan to sell
              them elsewhere?
            </div>
            <div className={styles.answer}>
              We have no plans to reprint these or sell them in any other
              context. This is probably your one shot to get one.
            </div>
          </div>
        </div>
      </ProductLayout>
    </div>
  )
}

export default CubeConPrintPage
