import './Base.scss'
import 'styles/reset.scss'

import React from 'react'

interface Props {
  children: React.ReactNode
}

/**
 * Container that should be used on all pages on the site with no visual
 * components.
 */
export const BaseLayout: React.FC<Props> = (props) => {
  const { children } = props

  return <>{children}</>
}
