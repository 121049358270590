import * as styles from './OpenSurveyBanner.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import ProseList from 'components/ProseList'

interface Props {
  sets: readonly {
    name: string | null
    code: string | null
  }[]
  dismissable?: boolean
  dismiss?(): void
}

const OpenSurveyBanner: React.FC<Props> = (props) => {
  const { sets, dismissable, dismiss } = props

  return (
    <div className={styles.container}>
      {sets.length > 1 ? (
        <>
          We currently have Cube testing surveys open for{' '}
          <ProseList>
            {sets.map((set) => (
              <Link key={set.code} to={`/survey/${set.code}/`}>
                {set.name}
              </Link>
            ))}
          </ProseList>
          . Let us know what cards you&rsquo;re testing from these sets!{' '}
          {props.dismissable && (
            <button
              className={styles.dismissButton}
              type="button"
              onClick={dismiss}
            >
              Dismiss
            </button>
          )}
        </>
      ) : (
        <>
          Our Cube testing survey for{' '}
          {sets.map<React.ReactNode>((set) => (
            <Link key={set.code} to={`/survey/${set.code}/`}>
              {set.name}
            </Link>
          ))}{' '}
          is open. Let us know what cards you&rsquo;re most excited about!{' '}
          {dismissable && (
            <button
              className={styles.dismissButton}
              type="button"
              onClick={dismiss}
            >
              Dismiss
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default OpenSurveyBanner
