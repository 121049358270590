import React from 'react'

interface Props {
  children: React.ReactNode[]
}

const ProseList: React.FC<Props> = (props) => {
  const items = props.children

  if (items.length === 1) {
    return <>{items}</>
  }

  if (items.length === 2) {
    return <>{[items[0], ' and ', items[1]]}</>
  }

  return (
    <>
      {items.reduce<React.ReactNode[]>(
        (sentence: React.ReactNode[], item, index) => [
          ...sentence,
          index === 0 ? null : index === items.length - 1 ? ', and ' : ', ',
          item,
        ],
        [],
      )}
    </>
  )
}

export default ProseList
